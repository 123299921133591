import { format } from 'date-fns';

export const calculateOverlapPercentage = ({
  timeline,
  point,
}: {
  timeline: { start: number; end: number };
  point: { start: number; end: number };
}) => {
  const overlapStart = Math.max(timeline.start, point.start);
  const overlapEnd = Math.min(timeline.end, point.end);
  const overlapLength = overlapEnd - overlapStart;
  const timelineLength = timeline.end - timeline.start;
  const overlapStartPercentage = ((overlapStart - timeline.start) / timelineLength) * 100;
  const overlapEndPercentage = ((overlapEnd - timeline.start) / timelineLength) * 100;

  if (overlapLength <= 0) {
    return false;
  }

  return {
    overlapStartPercentage,
    overlapEndPercentage,
  };
};

export const findClosest = (number1: number, number2: number) => {
  const firstNumber = { number: number1, closestValues: [number1 % 1, 1 - (number1 % 1)] };
  const secondNumber = { number: number2, closestValues: [number2 % 1, 1 - (number2 % 1)] };
  const theClosestNumber = Math.min(...firstNumber.closestValues, ...secondNumber.closestValues);

  if (firstNumber.closestValues.includes(theClosestNumber)) {
    return firstNumber.number;
  }

  return secondNumber.number;
};

export const getStepSize = (totalFilesDuration: number) => {
  if (!totalFilesDuration) {
    return { stepSize: 0, numberOfSteps: 0 };
  }

  const POSSIBLE_NUMBER_OF_STEPS = [4, 5, 6, 7];
  const stepSizesPerStep = POSSIBLE_NUMBER_OF_STEPS.reduce(
    (acc, numberOfSteps) => {
      const stepSize = totalFilesDuration / numberOfSteps;
      acc.push({ stepSize, numberOfSteps });

      return acc;
    },
    [] as { numberOfSteps: number; stepSize: number }[]
  );
  /**
   * We are calculating the remainder number that's left after using modulo to find out which number is the closest to it's absolute number.
   * E.g. if we got 1.25, 5.25, 9.95 the closest number to have the best steps would be 9.95, because it's the closest to absolute number (10)
   */
  const mostSuitableStepSize = stepSizesPerStep.reduce(
    (acc, { numberOfSteps, stepSize }) => {
      if (acc.stepSize === 0) {
        acc = { numberOfSteps, stepSize };
      }

      const closestNumber = findClosest(acc.stepSize, stepSize);
      if (closestNumber === stepSize) {
        acc = { numberOfSteps, stepSize };
      }

      return acc;
    },
    { numberOfSteps: 0, stepSize: 0 }
  );

  return mostSuitableStepSize;
};

export const formatTime = (seconds: number, totalFilesDuration: number) => {
  const isLongerThanHour = totalFilesDuration / 60 / 60 > 1;
  const date = new Date();

  // The date is returned with the current timezone (+01:00), so we need to reset it to 00:00
  // otherwise the timestamp starts from 1 hour
  date.setHours(0, 0, seconds);

  if (isLongerThanHour) {
    return format(date, 'HH:mm') + 'h';
  }

  return format(date, 'mm:ss') + 'm';
};
