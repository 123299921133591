import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { FileMarker } from 'api/queries/patient/usePatientFiles';
import { COLORS } from '../../../constants';
import { GroupedFiles } from 'pages/Calendar';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon } from 'assets/icons/ArrowDownIcon';
import { formatDuration } from 'utils/dateUtils';
import { parseSeizureEnum } from 'utils/seizureEnum';
import { getDetectedByDoctorMarks, getReviewedByDoctorMarks } from '../utils';

interface DaySummaryProps {
  dayDetails: GroupedFiles;
  defaultOpen: boolean;
}

export const DaySummary: FC<DaySummaryProps> = ({ dayDetails, defaultOpen }) => {
  const [expanded, setExpanded] = useState(defaultOpen);

  const { t } = useTranslation();

  const reviewedByDoctorMarks = getReviewedByDoctorMarks(dayDetails.files);
  const reviewedByDoctorMarksAmount = reviewedByDoctorMarks.length;
  const detectedMarks = getDetectedByDoctorMarks(dayDetails.files);
  const detectedMarksAmount = detectedMarks.length;

  const totalReviews = dayDetails.files.flatMap((file) => file.markers).length;

  const getMarkerDescription = (marker: FileMarker) => {
    const { doctorReview } = marker;

    if (!doctorReview) return '-';

    const status = t(`reviewResult.${doctorReview.reviewResult}`);
    const parsedSeizureEnum = doctorReview?.seizureType
      ? parseSeizureEnum(doctorReview.seizureType)
      : null;
    const seizureTypeText = parsedSeizureEnum
      ? parsedSeizureEnum.map((seizureType) => t(`reviewSeizure.${seizureType}`)).join(', ')
      : null;

    const parts = [status, seizureTypeText, doctorReview.description].filter(Boolean);
    return parts.join(', ');
  };

  return (
    <Accordion
      expanded={expanded}
      disableGutters
      onChange={() => setExpanded(!expanded)}
      sx={{
        bgcolor: 'transparent',
        boxShadow: 'none',
        borderBottom: `1px solid #67798A70`,
        borderRadius: '0 !important',
      }}
    >
      <AccordionSummary expandIcon={<ArrowDownIcon />} sx={{ px: 0 }}>
        <Typography variant="h4" mr="70px">
          {dayDetails.date}
        </Typography>
        <Stack direction="row" gap="20px" alignSelf="center" marginLeft={'auto'}>
          <Typography variant="P3_SB">
            {t('calendar.summary.totalMarks')}:{' '}
            <Typography variant="P4_R" ml="2px">
              {totalReviews}
            </Typography>
          </Typography>
          <Typography variant="P3_SB">
            {t(`reviewResult.DETECTED`)}:{' '}
            <Typography
              variant="P4_R"
              ml="2px"
            >{`${detectedMarksAmount}/${reviewedByDoctorMarksAmount}`}</Typography>
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ p: '4px 0 16px 0' }}>
        <Stack>
          {dayDetails.files.map((file, videoIndex) =>
            file.markers.map((marker, markerIndex) => (
              <Stack
                key={marker.id}
                p="8px 12px"
                gap="4px"
                bgcolor={videoIndex % 2 ? 'inherit' : COLORS.WHITE}
              >
                <Stack direction="row" gap="24px">
                  <Typography variant="P5_R">{`Video #${videoIndex + 1}`}</Typography>
                  <Typography variant="P5_R">{`Mark #${markerIndex + 1}`}</Typography>
                  <Typography variant="P5_R">{`Time: ${formatDuration(
                    marker.startSecond
                  )}`}</Typography>
                </Stack>
                <Typography variant="P4_R">{getMarkerDescription(marker)}</Typography>
              </Stack>
            ))
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
