import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useCreateMarker } from 'api/queries/markers/useCreateMarker';
import { useDeleteMarker } from 'api/queries/markers/useDeleteMarker';
import { FileMarker } from 'api/queries/patient/usePatientFiles';
import { ArrowLeft } from 'assets/icons/ArrowLeft';
import { ArrowRight } from 'assets/icons/ArrowRight';
import { TrashIcon } from 'assets/icons/TrashIcon';
import { COLORS } from '../../../constants';
import { useVideoPlayerContext } from 'contexts/VideoPlayerContext';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createMarkerParam } from 'components/PatientReview';
import styled from '@emotion/styled';
import { MARKER_END_SECOND_OFFSET } from 'components/Timeline/Marker';

const TIME_OFFSET = 10;

interface MarkersControlsProps {
  markers: FileMarker[];
  fileId: string;
  fileDuration: number;
  disabled: boolean;
}

const DEBOUNCE_DELAY = 250;

export const MarkersControls: FC<MarkersControlsProps> = ({
  markers,
  fileId,
  fileDuration,
  disabled,
}) => {
  const { videoState, videoPlayerRef } = useVideoPlayerContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const sortedMarkers = useMemo(
    () => [...markers].sort((a, b) => a.startSecond - b.startSecond),
    [markers]
  );

  const activeMarker = useMemo(() => {
    const id = searchParams.get('markerId');
    const index = sortedMarkers.findIndex((marker) => marker.id === id);
    return { id, index };
  }, [searchParams, sortedMarkers]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const unselectMarker = () => {
        /**
         * Filtering may leave us in a situation, where we got an active markerId, which is not present in the sortedMarkers array.
         * In this case, we should remove the markerId from the URL to make the user select an existing marker again
         *
         * Debounce is needed, because we have got a case where deleting a marker (unselect) conflicts with navigating to the next marker
         * leaving us with no selected marker, even though we have markers available
         **/
        if (activeMarker.index === -1 && activeMarker.id) {
          navigate({ search: '' });
        }
      };
      unselectMarker();
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(delayDebounceFn);
  }, [navigate, activeMarker]);

  useEffect(() => {
    const seekToMarker = () => {
      /**
       * Initially, I have seeked to markers on every handler, however, we needed logic for blocking the navigation
       * in certain situations (e.g. not filling up a freshly created doctor type marker), so it caused a situation where the navigation is blocked,
       * but the playback was moving forward. Thanks to this seeking logic is tied to navigation state and is controlled in one (almost) place only.
       */
      if (!videoPlayerRef.current || !activeMarker.id || activeMarker.index === -1) {
        return;
      }

      const currentTime = videoPlayerRef.current.getCurrentTime();
      const isPlaybackWithinMarker =
        sortedMarkers[activeMarker.index].startSecond <= currentTime &&
        currentTime <=
          (sortedMarkers[activeMarker.index].endSecond || fileDuration) + MARKER_END_SECOND_OFFSET;
      if (isPlaybackWithinMarker) {
        return;
      }

      videoPlayerSeekTo(sortedMarkers[activeMarker.index].startSecond);
    };

    seekToMarker();
  }, [activeMarker, videoPlayerRef, sortedMarkers]);

  const areMarksAvailable = markers.length > 0;

  const { t } = useTranslation();

  const { mutate: createMarkerMutation, isPending: isCreatingMarker } = useCreateMarker();
  const { mutate: deleteMarkerMutation, isPending: isDeletingMarker } = useDeleteMarker();

  const playedSeconds = Math.floor(videoState.playedSeconds);

  const isPreviousMarkerAvailable = activeMarker.index > 0;
  const isNextMarkerAvailable = sortedMarkers.length - 1 > activeMarker.index;

  const goToPreviousMarker = () => {
    navigate({
      search: createMarkerParam(sortedMarkers[activeMarker.index - 1].id),
    });
  };

  const goToNextMarker = () => {
    navigate({
      search: createMarkerParam(sortedMarkers[activeMarker.index + 1].id),
    });
  };

  const createMarker = () => {
    const startSecond = playedSeconds > TIME_OFFSET ? playedSeconds - TIME_OFFSET : 0;
    const endSecond =
      playedSeconds + TIME_OFFSET > fileDuration ? fileDuration : playedSeconds + TIME_OFFSET;

    createMarkerMutation(
      {
        fileId,
        data: { startSecond: Math.floor(startSecond), endSecond: Math.floor(endSecond) },
      },
      {
        onSuccess: (data) => {
          navigate({ search: createMarkerParam(data.id) }, { state: 'force' });
        },
      }
    );
  };

  const isCreateMarkerAvailable = sortedMarkers.every((marker) => {
    return (
      playedSeconds < marker.startSecond - TIME_OFFSET ||
      playedSeconds > (marker.endSecond || fileDuration) + TIME_OFFSET
    );
  });

  const deleteMarker = () => {
    if (!activeMarker.id) return;

    deleteMarkerMutation(
      { fileId, markerId: activeMarker.id },
      {
        onSuccess: () => {
          const getMarkerId = () => {
            if (markers[0]?.id === activeMarker.id) {
              if (markers.length > 1) {
                return markers[1]?.id;
              } else {
                return null;
              }
            }
            return markers[0]?.id;
          };

          const markerId = getMarkerId();
          const marker = markers.find((marker) => marker.id === markerId);
          if (!marker) {
            return;
          }

          navigate({ search: createMarkerParam(marker.id) }, { state: 'force' });
        },
      }
    );
  };

  const handleSelectClosestMarker = () => {
    if (sortedMarkers.length > 0) {
      navigate({
        search: createMarkerParam(sortedMarkers[0].id),
      });
    }
  };

  const videoPlayerSeekTo = (amount: number) => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(amount);
    }
  };

  const handleReselectMarker = () => {
    videoPlayerSeekTo(sortedMarkers[activeMarker.index].startSecond);
  };

  return (
    <Stack direction="row" gap={2} justifyContent="space-between">
      <Box>
        {areMarksAvailable && !activeMarker.id && (
          <MarkerButton sx={{ cursor: 'pointer' }} onClick={handleSelectClosestMarker}>
            <Typography variant="P1_R" color={COLORS.SECONDARY} sx={{ mx: 1 }}>
              {t('patient.selectFirstMarker')}
            </Typography>
          </MarkerButton>
        )}
        {areMarksAvailable && activeMarker.id && (
          <MarkerButton>
            <IconButton disabled={!isPreviousMarkerAvailable} onClick={goToPreviousMarker}>
              <ArrowLeft />
            </IconButton>

            <Typography
              variant="P1_R"
              sx={{ mx: 1, cursor: 'pointer' }}
              color={COLORS.SECONDARY}
              onClick={handleReselectMarker}
            >
              {`Mark #${activeMarker.index + 1}`}
            </Typography>
            <IconButton disabled={!isNextMarkerAvailable} onClick={goToNextMarker}>
              <ArrowRight />
            </IconButton>
          </MarkerButton>
        )}
        {!areMarksAvailable && (
          <MarkerButton
            sx={{
              opacity: 0.5,
            }}
          >
            <Typography variant="P1_R" sx={{ mx: 1 }}>
              {t('markers.noMarks')}
            </Typography>
          </MarkerButton>
        )}
      </Box>

      {!disabled && (
        <Stack direction="row" gap={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={createMarker}
            disabled={isCreatingMarker || !isCreateMarkerAvailable || isDeletingMarker}
          >
            {t('patient.addNewMarker')}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={deleteMarker}
            disabled={isCreatingMarker || isDeletingMarker || !sortedMarkers[activeMarker.index]}
            sx={{
              padding: '10px',
            }}
          >
            <TrashIcon />
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

const MarkerButton = styled(Box)(() => ({
  display: 'flex',
  height: '50px',
  padding: '10px 36px',
  borderRadius: '200px',
  background: COLORS.PRIMARY,
  alignItems: 'center',
  mr: 'auto',
}));
