import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../index';
import { baseClient } from '../client/baseClient';
import { REVIEW_RESULT_STATUS } from './constants';
import { REVIEW_STATUS } from 'contexts/PatientListProvider';

export type MarkerType = 'ML_CREATED' | 'DOCTOR_CREATED' | 'USER_FEEDBACK';
export type ReviewType = 'DOCTOR' | 'ML';

interface Review {
  createdAt: string;
  description: string;
  reviewResult: REVIEW_RESULT_STATUS;
  seizureType: string;
  reviewerFirstName: string;
  reviewerLastName: string;
  reviewType: ReviewType;
  updatetAt: string | null;
}

export type FileMarker = {
  id: string;
  type: MarkerType;
  startSecond: number;
  endSecond: number | null;
  doctorReview: Review | null;
  mlReview: Review | null;
  number: number;
};

export type PatientFileRO = {
  fileId: string;
  fileName: string;
  fileCreatedAt: string;
  fileUploadedAt: string;
  fileReviewStatus: REVIEW_STATUS;
  fileReviewResult: string;
  thumbnailUrl: string;
  reviewResult: REVIEW_RESULT_STATUS;
  markers: FileMarker[];
  lengthInSeconds: number;
};

export type PatientFilesRO = {
  patientId: string;
  patientFirstName: string | null;
  patientLastName: string | null;
  caseName: string | null;
  files: PatientFileRO[];
};

type usePatientsProps = {
  patientId: string;
};

export const usePatientFiles = (props: usePatientsProps) =>
  useQuery({
    queryKey: [QUERY_KEYS.PATIENT_FILES, props],
    queryFn: () => verification(props),
  });

const verification = async ({ patientId }: usePatientsProps): Promise<PatientFilesRO> => {
  return baseClient({
    url: `/patients/${patientId}/files`,
    method: 'GET',
  });
};
