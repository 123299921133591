import { Timeline } from 'components/Timeline';
import { Timestamps } from 'components/Timeline/Timestamps';
import { t } from 'i18next';
import { PatientFileRO } from 'api/queries/patient/usePatientFiles';
import { CollapseSection } from './CollapseSection';

interface GeneralSectionProps {
  currentDateFiles?: PatientFileRO[];
  duration: number;
  totalFilesDuration?: number;
}

export const GeneralSection = ({
  currentDateFiles,
  duration,
  totalFilesDuration,
}: GeneralSectionProps) => {
  if (!currentDateFiles) {
    return null;
  }

  return (
    <CollapseSection label={t('patient.sessionPreview')}>
      <Timeline files={currentDateFiles} totalFilesDuration={totalFilesDuration!} />
      <Timestamps totalFilesDuration={duration} />
    </CollapseSection>
  );
};
