import { Stack } from '@mui/material';
import { PatientFileRO } from 'api/queries/patient/usePatientFiles';
import { Marker, MARKER_END_SECOND_OFFSET } from './Marker';
import { FC } from 'react';
import { calculateOverlapPercentage } from './utils';
import { Bar } from './Bar';
import { useNavigate, useParams } from 'react-router-dom';
import { getVideoPageLink } from 'components/calendar/utils';

interface TimelineProps {
  files: PatientFileRO[];
  totalFilesDuration: number;
  startTime?: number;
}

export const Timeline: FC<TimelineProps> = ({ files, totalFilesDuration, startTime = 0 }) => {
  const { patientId } = useParams<{ patientId: string }>();
  const navigate = useNavigate();

  const getFileWidth = (file: PatientFileRO) => {
    const percentage = (file.lengthInSeconds / totalFilesDuration) * 100;
    return percentage + '%';
  };

  const navigateToVideo = (file: PatientFileRO) => {
    const link = getVideoPageLink(file, patientId as string);
    navigate(link);
  };

  return (
    <Stack direction="row" gap="4px">
      {files.map((file) => (
        <Bar
          key={file.fileId}
          width={getFileWidth(file)}
          onClick={(e) => {
            e.stopPropagation();
            navigateToVideo(file);
          }}
        >
          {file.markers.map((marker) => {
            const isMarkerVisible = calculateOverlapPercentage({
              timeline: { start: startTime, end: startTime + file.lengthInSeconds },
              point: {
                start: marker.startSecond,
                end: (marker.endSecond || file.lengthInSeconds) + MARKER_END_SECOND_OFFSET,
              },
            });

            if (!isMarkerVisible) {
              return null;
            }

            return (
              <Marker
                marker={marker}
                startTime={startTime}
                fileDuration={file.lengthInSeconds}
                key={marker.id}
              />
            );
          })}
        </Bar>
      ))}
    </Stack>
  );
};
