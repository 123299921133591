import { Box, Typography } from '@mui/material';
import { Status } from '../Status';
import { useParams } from 'react-router-dom';
import { REVIEW_STATUS } from '../../contexts/PatientListProvider';
import { useTranslation } from 'react-i18next';
import { PatientReviewArrows } from '../PatientReviewArrows';
import { parseSeizureEnum } from 'utils/seizureEnum';
import { FC } from 'react';
import { useMarkerDetails } from 'hooks/useMarkerDetails';
import { useGetSummaries } from 'api/queries/summary/useGetSummaries';

export const PatientReviewResult: FC = () => {
  const { t } = useTranslation();
  const { patientId, selectedFileId } = useParams();

  const { activeMarker } = useMarkerDetails();
  const { data: summaries } = useGetSummaries(patientId as string);

  const seizureType = activeMarker?.doctorReview?.seizureType
    ? parseSeizureEnum(activeMarker.doctorReview.seizureType)
    : null;

  const summaryData = summaries?.summaries.find((summary) =>
    summary.fileIds.includes(selectedFileId as string)
  );

  const reviewResult = activeMarker?.doctorReview?.reviewResult;
  const reviewDescription = activeMarker?.doctorReview?.description;

  return (
    <Box sx={{ maxWidth: '498px', mt: '4px' }}>
      <Status variant={REVIEW_STATUS.REVIEWED} />
      {reviewResult && (
        <Typography variant="body2" sx={{ mt: 2 }}>
          {t('patient.result.title')}
        </Typography>
      )}

      <Box sx={{ minHeight: '160px' }}>
        {reviewResult && (
          <>
            <Typography variant="body2" sx={{ fontWeight: '600', mt: 1 }}>
              {t('patient.result.result')}
            </Typography>
            <Typography variant="body2">{t(`reviewResult.${reviewResult}`)}</Typography>
          </>
        )}

        {seizureType && (
          <>
            <Typography variant="body2" sx={{ fontWeight: '600', mt: 1 }}>
              {t('patient.result.type')}
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: 'break-spaces' }}>
              {seizureType.map((seizureType) => t(`reviewSeizure.${seizureType}`)).join('\n')}
            </Typography>
          </>
        )}

        {reviewDescription && (
          <>
            <Typography variant="body2" sx={{ fontWeight: '600', mt: 1 }}>
              {t('patient.result.comment')}
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
              {reviewDescription}
            </Typography>
          </>
        )}

        {summaryData && (
          <>
            <Typography variant="body2" sx={{ fontWeight: '600', mt: 1 }}>
              {t('dashboard.reviewer')}
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
              {summaryData.createdBy.firstName} {summaryData.createdBy.lastName}
            </Typography>
          </>
        )}
      </Box>

      <Box sx={{ mt: 2, gap: 2, display: 'flex', flexWrap: 'wrap' }}>
        <PatientReviewArrows />
      </Box>
    </Box>
  );
};
